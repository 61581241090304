import React, { useState, useEffect } from 'react';
import { updateImageQuality } from '../Firebase'; // Adjust the path as per your project structure

const SearchResult = ({ photoDoc, onStatusUpdate, updateImageQualityInState }) => {
    const [updatingIndex, setUpdatingIndex] = useState(null); // Track which image is being updated
    const [updatingIndexSpecial, setUpdatingIndexSpecial] = useState(null); // Track which image is being updated

    const handleImageClick = async (photoIndex, outputType) => {
        console.log(photoIndex, outputType)
        let newImageQuality = outputType == 'special' ? [...photoDoc.special_image_quality] : [...photoDoc.image_quality];
        newImageQuality[photoIndex] = newImageQuality[photoIndex] === 'bad' ? 'good' : 'bad';
        console.log("NIQ: ", newImageQuality)
        if(outputType == "special")
            setUpdatingIndexSpecial(photoIndex); // Indicate that update is in progress
        else
            setUpdatingIndex(photoIndex); // Indicate that update is in progress

        try {
            await updateImageQuality(photoDoc.id, newImageQuality, outputType);
            onStatusUpdate(photoDoc.email, photoIndex, newImageQuality[photoIndex], outputType);
            updateImageQualityInState(photoDoc.id, newImageQuality, outputType); // Update the state
        } catch (error) {
            console.error('Error updating document:', error);
            // Handle error
        }
        if(outputType == "special")
            setUpdatingIndexSpecial(null); // Reset the updating index
        else
            setUpdatingIndex(null); // Reset the updating index        
    };

    const getImageBorderStyle = (index, outputType) => {
        const quality = photoDoc[outputType == "special" ? "special_image_quality" : "image_quality"][index];
        return quality === 'bad' ? 'border-4 border-red-500' : 'border-4 border-green-500';
    };

    return (
        <div className="border border-gray-300 rounded-lg p-4 my-4">
            {photoDoc.email && <p className="text-gray-700 font-medium">Email: {photoDoc.email}</p>}
            {photoDoc.id && <p className="text-gray-700 font-medium">ID: {photoDoc.id}</p>}
            {photoDoc.timestamp && (
                <p className="text-gray-700 font-medium">Timestamp: {new Date(photoDoc.timestamp.seconds * 1000).toLocaleString()}</p>
            )}
             <p className="text-gray-700 font-medium">User uploaded</p>
            <div className="flex flex-wrap justify-around mt-3">
                {Array.isArray(photoDoc.photos.YourPet) && photoDoc.photos.YourPet.length > 0 ? (
                    photoDoc.photos.YourPet.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Output Image ${index + 1}`}
                            className={`w-1/12 h-auto p-1 object-contain`} // Adjusted width and added object-fit
                        />
                    ))
                ) : (
                    <p className="text-gray-700">No images found with key photos.YourPet.</p>
                )}
            </div>
            <p className="text-gray-700 font-medium">Output</p>
            <div className="flex flex-wrap justify-around mt-3">
                {Array.isArray(photoDoc.output_images) && photoDoc.output_images.length > 0 ? (
                    photoDoc.output_images.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Output Image ${index + 1}`}
                            className={`w-1/4 h-auto m-2 p-1 ${getImageBorderStyle(index, "nonspecial")}`}
                            onClick={() => handleImageClick(index, "nonspecial")}
                        />
                    ))
                ) : (
                    <p className="text-gray-700">No images found with key output_images.</p>
                )}
            </div>
            <p className="text-gray-700 font-medium">Special Output</p>
            <div className="flex flex-wrap justify-around mt-3">
                {Array.isArray(photoDoc.output_special_images) && photoDoc.output_special_images.length > 0 ? (
                    photoDoc.output_special_images.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Output Image ${index + 1}`}
                            className={`w-1/4 h-auto m-2 p-1 ${getImageBorderStyle(index, "special")}`}
                            onClick={() => handleImageClick(index, "special")}
                        />
                    ))
                ) : (
                    <p className="text-gray-700">No images found with key output_special_images.</p>
                )}
            </div>
        </div>
    );
};

export default SearchResult;
