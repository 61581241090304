import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import LandingPage from './LandingPage.jsx'
import Support from './Support'
import ResultsPage from './ResultsPage';
import VideoUploadPage from './VideoUploadPage';


const App = () => {

  return (
    <>
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/support" element={<Support />} />
          <Route path="/result/:docId" element={<ResultsPage />} />
          <Route path="/upload-video/:docId" element={<VideoUploadPage />} />
        </Routes>
      </div>
    </Router>
    </>
  );

};

export default App;