import React, { useState, useEffect } from 'react';
import { 
  uploadVideoToFirebase,
  updateVideoInFirestore
} from '../Firebase'; // Adjust the import path as needed
import { TailSpin } from 'react-loading-icons'


const UploadVideo = ({isDog, docId, documentHasVideo}) => {
  console.log("rendering video component...")
  
  const [video, setVideo] = useState(null);
  const [uploading, setUploading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = useState(documentHasVideo ? 1 : 0)

  const handleImageUpload = (event) => {
    console.log("handleImageUpload...")
    if (event.target.files) {
      const file = event.target.files[0];
      console.log(file);

      // Check if the file is a video and either MP4 or MOV
      if (file && (file.type === "video/mp4" || file.type === "video/quicktime")) {
        console.log("file is video...")
        const video = document.createElement("video");
        video.preload = 'metadata';

        video.onloadedmetadata = function() {
          console.log("onloaded callback...")
          window.URL.revokeObjectURL(video.src);
          if (video.duration > 5) {
            setErrorMessage('Please upload a video that is 5 seconds or shorter.');
          } else {
            console.log("storing video...")
            const newFile = {
              url: URL.createObjectURL(file),
              file: file
            };
            setVideo(newFile);
            setErrorMessage(''); // Clear any previous error messages
          }
        };

        video.src = URL.createObjectURL(file);
      } else {
        setErrorMessage('Please upload a video file in MP4 or MOV format.');
      }
    }
  };

  const removeImage = () => {
    setVideo(null)
    const fileInput = document.getElementById("videoInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    const videoUrl = await uploadVideoToFirebase(video);
    await updateVideoInFirestore(docId, videoUrl);
    setUploading(false);
    setState(1)
  }

  return (
    <div className="flex rounded-lg p-6 w-full bg-blue-900 text-white">
        <div className='flex-col md:flex-row flex w-full justify-center align-center'>
          <div className='flex flex-col w-full m-2 md:w-2/3'>

          <div className='flex flex-col'>
            <p className="text-2xl font-bold">Bonus video</p>
            <p className="text-xs">As a thank you for being an early user, we want to make you a bonus video!</p>
          </div>
          <div className='flex h-full justify-center items-center'>
            {
              state == 1 ?
              (
                <div className='flex flex-col p-4'>
                  Thanks for uploading your video! We'll email you once it's posted on your fluffball results page.
                </div>
              ) : (
              <div className='flex flex-col p-2'>
                <div className='py-2'>
                  <p className='font-bold '>Upload a 2 to 5 second video of your pet</p>
                </div>
                <div className='flex w-full align-center items-center justify-center'>

                <input
                  className="w-full file:border-0 file:rounded file:bg-blue-500 file:text-white file:py-2 file:px-4 file:cursor-pointer mb-4 text-sm"
                  type="file"
                  multiple={false}
                  onChange={handleImageUpload}
                  id={'videoInput'}
                />
                </div>

                { video !== null &&
                  <div className="grid grid-cols-6 gap-2">
                    <div className={`relative border-white border rounded`}>

                      <div className="w-full h-auto rounded-lg shadow" />
                      <p className='my-8 mx-2'>MP4</p>
                      <button
                        className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                        onClick={() => removeImage()}
                      >
                        X
                      </button>
                    </div>
                  </div>
                }
                {errorMessage && (
                  <div className="text-red-500 text-center mt-2 p-2 rounded">{errorMessage}</div>
                )}


                {/* Display error message if verification response is "incorrect" */}
                {uploading && 
                  <div className="flex flex-col justify-center align-center mt-4">
                    <div className="flex justify-center align-center mt-4"><TailSpin stroke="#FFFFFF" speed={.75} /></div>
                  </div>
                }
                <button
                  className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors block mx-auto mt-4 disabled:bg-red-300"
                  onClick={handleUpload}
                  disabled={video === null || uploading}
                >
                  {uploading ? 'Uploading.. this might take a few minutes' : video === null ? `Select a video to upload` : 'Upload'}
                </button>

              </div>
            )}
            </div>
          </div>
        </div>
    </div>
  );
};

export default UploadVideo;
