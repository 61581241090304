import React, { useState, useEffect } from 'react';

const ViewAndDownloadVideo = ({ yourVideo, sampleVideo }) => {

  const [selectedPlatform, setSelectedPlatform] = useState('TIKTOK')
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = yourVideo;
    link.download = 'YourVideo.mp4';
    link.target = '_blank'; // Open in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  // Event handlers for button clicks
  const handleInstagramClick = () => {
    setSelectedPlatform('REELS');
    // Placeholder for additional onClick logic
  };

  const handleTikTokClick = () => {
    setSelectedPlatform('TIKTOK');
    // Placeholder for additional onClick logic
  };


  return (
    <div className="bg-purple-600 p-8 rounded-lg flex text-white flex-col sm:flex-row w-full">
      <div className="flex-1 mr-4">
        <h2 className="text-4xl mb-4 font-bold">Post your video and go viral!</h2>
        <div>
            <div className="flex justify-center my-4">
              <button
                className={`border-2 border-white px-4 py-2 rounded-l-lg ${selectedPlatform === 'TIKTOK' ? 'bg-white text-purple-600' : 'bg-purple-600 text-white'}`}
                onClick={handleTikTokClick}
              >
                TikTok
              </button>
              <button
                className={`border-2 border-white px-4 py-2 rounded-r-lg ${selectedPlatform === 'REELS' ? 'bg-white text-purple-600' : 'bg-purple-600 text-white'}`}
                onClick={handleInstagramClick}
              >
                Instagram Reels
              </button>
            </div>
          </div>
          {
            selectedPlatform == 'TIKTOK' ? 
            (
              <div className="text-md mb-6">
                <p className="mb-2 font-bold">👇 Follow these steps:</p>
                <p className='my-2'>1. Click the red button to download the video</p>
                <p className='my-2'>2. Visit the original TikTok video from @pandasparties <a className='font-bold underline' href='https://www.tiktok.com/@pandasparties/video/7309587638657355050' target="_blank" rel="noopener noreferrer">here</a>.</p>
                <p className='my-2'>3. Upload the video to a new tiktok and use the audio from @pandasparties</p>
                <p className='my-2'>4. Add a cute caption and tag #fluffball and @fluffball.app.</p>
              </div>
            ) : (
              <div className="text-md mb-6">
                <p className="mb-2 font-bold">👇 Follow these steps:</p>
                <p className='my-2'>1. Click the red button to download the video</p>
                <p className='my-2'>2. Visit the original Instagram Reel from @pandasparties <a className='font-bold underline' href='https://www.instagram.com/reel/C0hzI4COos2/' target="_blank" rel="noopener noreferrer">here</a>.</p>
                <p className='my-2'>3. Upload the video to a new reel and use the audio from @pandasparties</p>
                <p className='my-2'>4. Caption it, use #fluffball, and tag @fluffball.app.</p>
              </div>  
        
            )
          }
        <div className='flex w-full justify-center'>
        <button
          onClick={handleDownload}
          className="bg-red-500 hover:bg-blue-700 text-white text-lg font-bold py-2 px-4 rounded"
        >
          Start by downloading your pet's video!
        </button>
        </div>
      </div>
      <div className="flex-1 video-container m-2 text-center">
        <p className="text-md font-semibold mb-2">Make a video like this! ⬇️</p>
        <video 
          className="max-w-xs max-h-96 mx-auto rounded" 
          controls 
          controlsList="nodownload" 
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={sampleVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p className="text-md font-semibold mt-2">(Put the volume up!)</p>
      </div>
    </div>
  );
};

export default ViewAndDownloadVideo;
