import React from 'react';

const Modal = ({ isOpen, handleClose, children, title }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <button 
          onClick={handleClose} 
          className="absolute top-0 right-0 mt-2 mr-2 bg-transparent text-gray-900 font-semibold rounded p-1 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="mt-3 text-center">
          <div className="mt-2 px-7 py-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;