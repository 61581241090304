import React from 'react';

const GoToPayment = ({email, petType}) => {
  return (
    <div className="bg-white rounded-lg max-w-sm p-4">
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">🐶 One more step!</h2>
      <p className="text-gray-600 mb-4">
        We're creating your pet photos!
      </p>
      <p className="text-gray-600 mb-4">
        Complete your purchase so that you can receive your photos.
      </p>

    {/* Order Summary */}
      <div className="border-t border-gray-200 py-4 text-xs">
        <div className="flex justify-between mb-2">
          <span className="text-gray-600 font-semibold">Your order</span>
        </div>
        <div className="flex justify-between mb-1">
          <span className="text-gray-600">Email</span>
          <span className="text-gray-600">{email}</span>
        </div>
        <div className="flex justify-between mb-1">
          <span className="text-gray-600">Pet</span>
          <span className="text-gray-600">{petType == 'cat' ? 'Cat' : 'Dog'}</span>
        </div>
      </div>


      {/* Pricing Table */}
      <div className="border-t border-b border-gray-200 py-4 text-xs">
        <div className="flex justify-between mb-2">
          <span className="text-gray-600">5 x Pet Photo</span>
          <span className="font-semibold text-gray-600">$19.99</span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="text-gray-600">Early Bird Discount</span>
          <span className="text-red-500">-$15.00</span>
        </div>
        <div className="flex justify-between mt-4">
          <span className="font-semibold text-lg text-gray-600">Price</span>
          <span className="font-bold text-lg text-gray-600">$4.99</span>
        </div>
      </div>

      <a
        href="https://buy.stripe.com/cN26pKdGR0wz0Xm9AA"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded my-4"
      >
        Check out
      </a>

      <p className="text-gray-600 text-xs">
        Thanks for using fluffball.app! As one of our early users, you're getting 75% off your photos.
      </p>
    </div>
  );
};

export default GoToPayment;
