import { v4 as uuidv4 } from 'uuid'; // for generating a unique ID for each file
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, updateDoc, setDoc, serverTimestamp, query, where, getDocs, getDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.FIRE_BASE_API_KEY,
  authDomain: "fastab-f08e9.firebaseapp.com",
  projectId: "fastab-f08e9",
  storageBucket: "fastab-f08e9.appspot.com",
  messagingSenderId: "439667307825",
  appId: "1:439667307825:web:1e0a4cda7ac3bda5376c10",
  measurementId: "G-31TL7C8J18"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage();
const db = getFirestore(app);

// Function to save email to Firebase
async function saveEmailToFirebase(email) {
  try {
    const docRef = doc(collection(db, "emails"));
    await setDoc(docRef, {
      email: email,
      timestamp: serverTimestamp() // This is how you call serverTimestamp in modular SDK
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function logCheckoutClick() {
  try {
    // Add a new document with a generated id.
    const docRef = doc(collection(db, "holiday_checkout_clicks"));
    await setDoc(docRef, {
      timestamp: serverTimestamp(), // Capture the exact time of click
      // You can add more fields here if you need to capture additional data
    });
    console.log("Checkout click logged with ID: ", docRef.id);
  } catch (e) {
    console.error("Error logging checkout click: ", e);
  }
}

const getFileExtension = (fileName) => {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
};


const onUploadPhotos = async (familyMembers, photos) => {

  const userId = uuidv4()
  const userDocRef = doc(db, "petPhotos", userId);
  let totalPhotos = 0;
  let uploadedPhotos = 0;
  console.log("Uploading photos: ", photos)

  // Calculate total number of photos to upload
  for (const member of familyMembers) {
    totalPhotos += (photos[member.name] || []).length;
  }

  try {
    const userPhotosMap = {};

    for (const member of familyMembers) {
      const memberPhotos = photos[member.name]?.files;
      if (!memberPhotos || memberPhotos.length === 0) continue;

      userPhotosMap[member.name] = [];

      for (const photo of memberPhotos) {
	      const photoId = uuidv4();
	      const fileExtension = getFileExtension(photo.name);
	      const photoRef = ref(storage, `christmasCards/${userId}/${member.name}/${photoId}.${fileExtension}`);
        const uploadTask = uploadBytesResumable(photoRef, photo);

        // Monitor the upload progress
        await new Promise((resolve, reject) => {
          uploadTask.on('state_changed', 
            (snapshot) => {
              // Update progress
            },
            (error) => {
              console.error('Upload failed: ', error);
              reject(error);
            }, 
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log(downloadURL)
              userPhotosMap[member.name].push(downloadURL);
              uploadedPhotos++;
              console.log(`${uploadedPhotos} of ${totalPhotos} photos uploaded`);
              resolve();
            }
          );
        });
      }
    }

    // Save the user's photos map to Firestore
    await setDoc(userDocRef, { photos: userPhotosMap, timestamp: serverTimestamp() });
    console.log("All photos uploaded successfully.");
    return userId; // Returning the UUID of the user's document
  } catch (error) {
    console.error("Error uploading photos: ", error);
    throw error; // Rethrowing the error for further handling
  }
};

const updateEmailAndPetTypeInFirebase = async (userId, email, petType, utmParams) => {
  const userDocRef = doc(db, "petPhotos", userId);
  try {
    await updateDoc(userDocRef, { email: email.toLowerCase(), petType: petType, utmParams: utmParams});
    console.log(userId)
    console.log("Email updated successfully");
  } catch (error) {
    console.error("Error updating email: ", error);
  }
};

const extractEmails = (text) => {
    const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g;
    return text.match(emailRegex) || [];
};

const searchEmails = async (extractedEmails, hasBeenReviewed) => {
    const petPhotosCollection = collection(db, 'petPhotos');
    const queries = extractedEmails.map(email => query(petPhotosCollection, 
    	where("email", "==", email),
    	where("has_been_reviewed", "==", hasBeenReviewed)
    ));
    
    try {
        const results = await Promise.all(queries.map(q => getDocs(q)));
        const documents = results.flatMap(result => 
            result.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
        return documents;
    } catch (error) {
        console.error('Error fetching documents:', error);
        throw error; // or handle it as needed
    }
};

const searchDocumentsByReviewer = async (reviewer, hasBeenReviewed) => {
    const petPhotosCollection = collection(db, 'petPhotos');
    const assignmentQuery = query(petPhotosCollection, 
    	where("reviewer", "==", reviewer),
    	where("has_been_reviewed", "==", hasBeenReviewed)
    );

    try {
        const querySnapshot = await getDocs(assignmentQuery);
        const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return documents;
    } catch (error) {
        console.error('Error fetching documents:', error);
        throw error; // or handle it as needed
    }
};

const updateImageQuality = async (docId, newImageQuality, outputType) => {
    try {
        if (outputType == "special")
          await updateDoc(doc(db, 'petPhotos', docId), { special_image_quality: newImageQuality, has_been_reviewed: true, });
        else
          await updateDoc(doc(db, 'petPhotos', docId), { image_quality: newImageQuality, has_been_reviewed: true, });
    } catch (error) {
        console.error('Error updating document:', error);
        throw error; // Or handle it as needed
    }
};

const addEmailsToShare = async (docId, emailsToShare, firstName) => {
    console.log(`first name: ${firstName}`)
    try {
      await updateDoc(doc(db, 'petPhotos', docId), { emails_to_share: emailsToShare, user_first_name: firstName });
      // await updateDoc(doc(db, 'petPhotos', docId), { user_first_name: firstName });
    } catch (error) {
        console.error('Error updating document:', error);
        throw error; // Or handle it as needed
    }
};

const updateChatImagesInFirestore = async (docId, imageUrls) => {
  const firestore = getFirestore();
  const docRef = doc(firestore, "petPhotos", docId); // Replace 'collectionName' with your actual collection name

  await updateDoc(docRef, {
    chat_images: imageUrls,
    timestamp: serverTimestamp()
  });
};


const uploadImageToFirebase = async (imageFile) => {
  const photoId = uuidv4();
  const fileExtension = imageFile.name ? imageFile.name.split('.').pop().toLowerCase() : 'png'; // Defaulting to 'jpg' if extension is missing

  // Set the metadata to make the file publicly accessible
  const metadata = {
    contentType: imageFile.type,
    cacheControl: 'public, max-age=31536000',
  };

  const photoRef = ref(storage, `chat_images/${photoId}.${fileExtension}`);
  const uploadTask = uploadBytesResumable(photoRef, imageFile.file);

  // Monitor the upload progress
  return await new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Update progress
      },
      (error) => {
        console.error('Upload failed: ', error);
        reject(error);
      }, 
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};

const uploadVideoToFirebase = async (videoFile) => {
  const videoId = uuidv4();
  const fileExtension = videoFile.name ? videoFile.name.split('.').pop().toLowerCase() : 'mp4'; // Defaulting to 'jpg' if extension is missing

  // Set the metadata to make the file publicly accessible
  const metadata = {
    contentType: videoFile.type,
    cacheControl: 'public, max-age=31536000',
  };

  const photoRef = ref(storage, `user_submitted_videos/${videoId}.${fileExtension}`);
  const uploadTask = uploadBytesResumable(photoRef, videoFile.file);

  // Monitor the upload progress
  return await new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Update progress
      },
      (error) => {
        console.error('Upload failed: ', error);
        reject(error);
      }, 
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};

const updateVideoInFirestore = async (docId, videoUrl) => {
  const firestore = getFirestore();
  const docRef = doc(firestore, "petPhotos", docId); // Replace 'collectionName' with your actual collection name

  await updateDoc(docRef, {
    pet_video: videoUrl,
    timestamp: serverTimestamp()
  });
};

async function getDocumentWithId(docId) {
    try {
        const docRef = doc(db, 'petPhotos', docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching document:", error);
        throw error;
    }
}

const updateSharedStatus = async (docId) => {
    try {
      await updateDoc(doc(db, 'petPhotos', docId), { shared_screenshots_verified: true });
      // await updateDoc(doc(db, 'petPhotos', docId), { user_first_name: firstName });
    } catch (error) {
        console.error('Error updating document:', error);
        throw error; // Or handle it as needed
    }
};



// const storeFile = (file, fileName) => {
//   return new Promise((resolve, reject) => {
//     const filePath = `audios/${fileName || uuidv4()}`;
//     const storageRef = ref(storage, filePath);

//     const uploadTask = uploadBytesResumable(storageRef, file);

//     uploadTask.on('state_changed', 
//       (snapshot) => {
//         // Observe state change events such as progress, pause, and resume
//       }, 
//       (error) => {
//         // Handle unsuccessful uploads
//         console.error('Upload failed', error);
//         reject(error);
//       }, 
//       async () => {
//         // Do something once upload is complete
//         try {
//           const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//           console.log('File available at', downloadURL);
//           resolve(downloadURL);
//         } catch (error) {
//           console.error('Failed to get download URL', error);
//           reject(error);
//         }
//       }
//     );
//   });
// };

export {
  app,
  storage,
  db,
  saveEmailToFirebase,
  logCheckoutClick,
  onUploadPhotos,
  updateEmailAndPetTypeInFirebase,
  searchEmails,
  extractEmails,
  updateImageQuality,
  searchDocumentsByReviewer,
  addEmailsToShare,
  uploadImageToFirebase,
  updateChatImagesInFirestore,
  getDocumentWithId,
  updateSharedStatus,
  uploadVideoToFirebase,
  updateVideoInFirestore
}