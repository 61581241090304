import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../Firebase';
import logo from '../images/mascot.png';
import Modal from '../About.jsx';
import UploadVideo from './UploadVideo.jsx';

const menuButtonClasses = "px-4 py-2 hover:bg-white hover:text-blue-500 transition-colors duration-300 rounded-md";
const aboutModalContent = (
  <div className="">
    <h2 className="text-lg font-semibold text-blue-800 mb-2">About</h2>
    <p className="text-sm text-gray-700">
      We built this app to help you bond with your special friend and see them at their cutest. Enjoy!
    </p>
  </div>
);

const VideoUploadPage = () => {
  const { docId } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [yourVideo, setYourVideo] = useState(false);
  const [sampleVideo, setSampleVideo] = useState(false);


  const handleOpenAboutModal = () => {
    setAboutModalOpen(true);
  };

  const handleCloseAboutModal = () => {
    setAboutModalOpen(false);
  };

  useEffect(() => {
    const fetchDocument = async () => {
      const db = getFirestore(app);
      const docRef = doc(db, "petPhotos", docId);

      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDocumentData(docSnap.data());
        } else {
          console.log("No such document!");
          setError("Document not found");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
        setError("Error fetching document");
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [docId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  console.log("returning...")
  return (
    <div className="container mx-auto p-4">
      <Modal isOpen={aboutModalOpen} handleClose={handleCloseAboutModal} title="About">
        {aboutModalContent}
      </Modal>
      <nav className="flex justify-between items-center p-4">
        <div className="flex items-center">
          <img src={logo} alt="Fluffball.app Logo" className="h-10 mr-2" />
          <span>fluffball</span>
        </div>
        <div className="flex gap-4">
          <button className={menuButtonClasses} onClick={handleOpenAboutModal}>About</button>
        </div>
      </nav>
      <div>
        
        <h2 className="text-3xl text-gray-700 font-semibold mb-3 mt-20">🎬 Make a movie for your {documentData.petType == 'dog' ? 'puppy' : 'kitten'}!</h2>
          <div className="flex flex-wrap justify-center px-2 -mx-2 my-2">
            
            <UploadVideo
              isDog={documentData.petType == 'dog' ? true : false}
              docId={docId}
              documentHasVideo={documentData.pet_video !== undefined && documentData.pet_video !== null}
            />
          </div>
      </div>
    </div>
  );
};

export default VideoUploadPage;
