import React, { useRef, useEffect } from 'react';

const TestimonialsSlider = ({ testimonials }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let isPaused = false;

    const moveSlider = () => {
      if (!isPaused && slider) {
        slider.scrollLeft += 1;
        if (slider.scrollLeft >= slider.scrollWidth / 2) {
          slider.scrollLeft = 0;
        }
      }
    };

    const interval = setInterval(moveSlider, 10); // Adjust speed here

    slider.addEventListener('mouseenter', () => (isPaused = true));
    slider.addEventListener('mouseleave', () => (isPaused = false));

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-center p-4">
      <h2 className="text-lg font-semibold mb-4">What people are saying about us:</h2>
      <div ref={sliderRef} className="flex whitespace-nowrap overflow-x-hidden">
        {[...testimonials, ...testimonials].map((testimonial, index) => (
          <div key={index} className="inline-block mx-4">
            <p className="bg-blue-700 p-2 rounded">{testimonial}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsSlider;
