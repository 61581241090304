import React, { useState, useEffect } from 'react';
import logo from './images/mascot.png';
import './App.css';
import Modal from './About.jsx';
import { logCheckoutClick, onUploadPhotos, updateEmailAndPetTypeInFirebase } from './Firebase.js';
import EmailSignup from './EmailSignup.jsx';

import AddPeople from './UploadPhotos/AddPeople.jsx'
import PhotoUploader from './UploadPhotos/PhotoUploader.jsx'
import AddEmail from './UploadPhotos/AddEmail.jsx'
import GoToPayment from './UploadPhotos/GoToPayment.jsx'
import PetSelector from './UploadPhotos/PetSelector.jsx'
import InstagramWall from './InstagramWall.jsx'
import TestimonialsSlider from './TestimonialsSlider.jsx'

const menuButtonClasses = "px-4 py-2 hover:bg-white hover:text-blue-500 transition-colors duration-300 rounded-md";

// const testimonials = [
//   { id: 1, name: "Paws P. 🐾", text: "Seeing my Max as a tiny pup again brought tears to my eyes. This app is a gem!" },
//   { id: 2, name: "Bella B. 🐶", text: "It's like a sweet throwback in time! Our puppy pics are now the talk of every pet parent meetup." },
//   { id: 3, name: "Whiskers W. 🐱", text: "Who knew Mr. Fluffy was such a cute kitten? This app brings back so many memories." },
//   { id: 4, name: "Fido F. 🦴", text: "From grown-up to puppy in seconds – I can't get enough of this app. It's paw-sitively amazing!" },
//   { id: 5, name: "Coco C. 🐾", text: "Adorable, nostalgic, and so much fun! Now I can see Coco as a puppy anytime I want." },
// ];

  const instagramPosts = [
    // Example posts
    {
      imageUrl: 'ig_photos/nutterbutterrescuepupper.png',
      caption: 'Peanut woke up and said, “let’s break the internet today!” I’ve always wondered what puppy Peanut looked like. I was told he was part of a hoarding case in California the first year of his life with a sister who didn’t make it. He then somehow made it to Reno for 5 years before coming to me via the shelter as a medical surrender. Thank you Sam at @fluffball.app for these photos. 🥹❤️',
      username: 'nutterbutterrescuepupper', 
      timestamp: '2023-11-26', 
      link: 'https://www.instagram.com/p/C0HbiTIJSF4/?img_index=1',
    },
    {
      imageUrl: 'ig_photos/piggiebully.png',
      caption: 'Since we adopted Piggie at age 6, and we have no way to track down his history, we are so thankful for @fluffball.app for these AI renderings of Piggie as a puppy! I love that he even has his little white speckles on his chin and top of his nose. And of course…. His precious floppy ears.',
      username: 'piggiebully', 
      timestamp: '2023-11-27', 
      link: 'https://www.instagram.com/p/C0J3ubNLGI3/?img_index=1',
    },
    {
      imageUrl: 'ig_photos/seniorprofile.png',
      caption: 'I’ve always wanted to know what Henny looked like as a puppy, and @fluffball.app gave me that chance. AI technology made me my Henny bear as a CUTE WITTLE PUPPY 😭 #hennybear #adoptasenior #adoptdontshop #missyou🌈 #rainbowbridge #seniordog #rescueismyfavoritebreed #australianterrier',
      username: 'seniorprofile', 
      timestamp: '2023-11-26', 
      link: 'https://www.instagram.com/p/C0Ikr0tOdVO/?img_index=2',
    },
    { 
      imageUrl: 'ig_photos/howlandwolfe.png',
      caption: 'Mumma and Dad didn’t get to see me as a little pupper and always tell me how sad it makes them. But thanks to @fluffball.app (and @hi.this.is.tatum!) they finally got to see de baby Howie!',
      username: 'howlandwolfe', 
      timestamp: '2023-11-19', 
      link: 'https://www.instagram.com/p/Cz1UqjYLWfV/?hl=en&img_index=2',
    },
    { 
      imageUrl: 'ig_photos/wojpups.png',
      caption: `We have no idea what Otis looked like as a puppy, but our friends at @fluffball.app created this puppy photo of Otis.

What do you think? Do you think that's what Otis looked like as a puppy?`,
      username: 'wojpups', 
      timestamp: '2023-11-19', 
      link: 'https://www.instagram.com/p/Cz1kGg2Aeyj/?hl=en',
    },
    { 
      imageUrl: 'ig_photos/blindboyblaze.png',
      caption: `(Part 1) I caved and did the AI "see your dog as a puppy" and oh my goodness I am emotional! 😭😭 his body looks a little odd but hey, asking AI to do Blaze is a big ask 😂
These first few photos you can tell are built from the pictures I have from his adoption profile, which I put in there to compare. But wait til you see what they made from his portraits from @gonetothedogsphotography.....
(This magic was created using @fluffball.app)`,
      username: 'blindboyblaze', 
      timestamp: '2023-11-19', 
      link: 'https://www.instagram.com/p/Cz1LVKDuZTH/?hl=en',
    },
    { 
      imageUrl: 'ig_photos/tangled_up_in_peis.png',
      caption: 'Thanks to @fluffball.app I have an idea of what my sweet Sargie might have looked like as a puppy. I may or may not have burst out into tears when I saw the photos ❤️❤️❤️ I always wonder what my old man would have been like when he was a puppy, no doubt he was just as sweet and cute as he is now. #imnotcryingyourecrying #sgtpeppertherescuepei #sweetsargie #fluffballapp',
      username: 'tangled_up_in_peis', 
      timestamp: '2023-11-19', 
      link: 'https://www.instagram.com/p/Cz3p40DLA5O/?hl=en',
    },
    { 
      imageUrl: 'ig_photos/southerntofu__.png',
      caption: 'Thanks to the @fluffball.app I now have somewhat of an idea of what my other half looked like as a puppy. It’s crazy what AI can give us. I miss this guy daily and this was just something so special because I never got the pleasure of seeing him as a puppy. Thank you @fluffball.app',
      username: 'southerntofu__', 
      timestamp: '2023-11-19', 
      link: 'https://www.instagram.com/p/Cz2aNB9rBt9/?hl=en&img_index=2',
    },
    { 
      imageUrl: 'ig_photos/chugsmeanmugs.png',
      caption: 'We adopted Chugs at the age of 5 so we never got to see him as a puppy. Thanks to @fluffball.app we have a better idea of what he could have looked like. Oh how we wish you were a puppy with us, but still grateful you are in your furever home.🐶❤️',
      username: 'chugsmeanmugs', 
      timestamp: '2023-11-20', 
      link: 'https://www.instagram.com/p/Cz2DQpAvvWr/?hl=en&img_index=2',
    },

    { 
      imageUrl: 'ig_photos/mydogmeatloaf.png',
      caption: 'According to AI- here is what I looked like as a puppy @longislandbulldogrescue Thank you @fluffball.app for the great pics!!',
      username: 'mydogmeatloaf', 
      timestamp: '2023-11-20', 
      link: 'https://www.instagram.com/p/Cz1_OJvPHQQ/?hl=en&img_index=5',
    },
    { 
      imageUrl: 'ig_photos/annelieseeee.png',
      caption: 'I got an AI image of what Aurora may have looked like as a pup since I adopted her when she was 2 🥹 my sweet girl',
      username: 'annelieseeee', 
      timestamp: '2023-11-19', 
      link: 'https://www.instagram.com/p/Cz1zwfbO9rY/?hl=en&img_index=2',
    },

  ];

const testimonials = [
  "OMG they’re perfect!!!! Thank you so much! 😭😭😍😍",
  "Yes I did get pictures and they were GREAT!! Thank you so much!",
  "Thank you so much for doing it!!! I’ve always wanted to see my rescues as puppies",
  "They are beautiful. Thank you. 🥹🤎",
  "Received! love them, thank you!",
  "I can’t even explain how much I LOVE these!!!  Thank you so so so much 💙",
  "Omg my heart is melting. Thank you so much! ",
  "Amazing!! 😍",
  "Yup! I loved them so much I submitted another pet ☺😀",
  "THANK YOU!!! This means so much, I lost my Chloe 3 years ago and she was a rescue.",
  "Thank you so much for these!! It’s incredible seeing him as a baby!!! Thank you again!!",
]
// const Testimonial = ({ testimonial }) => (
//   <div className="bg-blue-700 p-4 rounded-lg">
//     <p className="text-sm font-bold sm:text-lg">{testimonial.text}</p>
//     <p className="text-xs sm:text-sm text-white mt-2">- {testimonial.name}</p>
//   </div>
// );

const LandingPage = () => {
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [getStartedModalOpen, setGetStartedModalOpen] = useState(false);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);


  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modalPage, setModalPage] = useState(1);
  const [people, setPeople] = useState(null)
  const [userId, setUserId] = useState(null)
  const [petType, setPetType] = useState(null)
  const [email, setEmail] = useState(null)
  const [utmParams, setUTMParams] = useState({});

  const getUTMParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      utm_source: params.get('utm_source') || '',
      // Add other UTM parameters as needed
    };
  };  

  useEffect(() => {
    setUTMParams(getUTMParams());
    const intervalId = setInterval(() => {
      setCurrentTestimonialIndex(currentIndex =>
        currentIndex < testimonials.length - 1 ? currentIndex + 1 : 0
      );
    }, 3000); // Change the testimonial every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleFileChange = (event) => {
    if (event.target.files.length > 20) {
      alert("You can only upload up to 20 images.");
      return;
    }
    setSelectedFiles([...event.target.files]);
  };

  const handleOpenAboutModal = () => {
    setAboutModalOpen(true);
  };

  const handleCloseAboutModal = () => {
    setAboutModalOpen(false);
  };

  const handleOpenGetStartedModal = () => {
    setGetStartedModalOpen(true);
  };

  const handleCloseGetStartedModal = () => {
    setGetStartedModalOpen(false);
    setModalPage(1);
  };
  const handleNextAfterUpload = () => {
    setModalPage(2);
  };

  const handleGoToCheckout = () => {
    logCheckoutClick() // Log the checkout click event to Firebase.
      .then(() => {
        setModalPage(3); // Update the modal page to show email signup
      })
      .catch((error) => {
        // Handle any errors in logging the click here.
        console.error("Error logging checkout click: ", error);
      });
  }; 

  const aboutModalContent = (
    <div className="">
      <h2 className="text-lg font-semibold text-blue-800 mb-2">About</h2>
      <p className="text-sm text-gray-700">
        We built this app to help you bond with your special friend and see them at their cutest. Enjoy!
      </p>
    </div>
  );



  let getStartedModalContent;
  // copied from holiday card
  // if (modalPage == 1) {

  //   const onSubmit = (people) => {
  //     setModalPage(2)
  //     setPeople(people)
  //     console.log(people)
  //   }
  //   getStartedModalContent = <AddPeople onSubmit={onSubmit}/>

  // }
  if (modalPage == 1) {
    const onSubmitPetType = (petType) => {
      setPetType(petType)
      setModalPage(2)
    }
    getStartedModalContent = <PetSelector 
      onSubmit={onSubmitPetType}
    />

  } else if (modalPage == 2) {
    const onSubmit = (userId) => {
      setUserId(userId)
      setModalPage(3)
    }
    getStartedModalContent = <PhotoUploader 
      familyMembers={[{name:'YourPet'}]}
      // familyMembers={people}
      onUpload={onUploadPhotos}
      onSubmit={onSubmit}

    />
    
  } 
  else if (modalPage == 3) {
    const onSubmitEmail = async (userId, email) => {
      setEmail(email)
      await updateEmailAndPetTypeInFirebase(userId, email, petType, utmParams)
      setModalPage(4)

    }
    getStartedModalContent = <AddEmail userId={userId} onSubmitEmail={onSubmitEmail}/>
  } else if (modalPage == 4) {
    getStartedModalContent = <GoToPayment email={email} petType={petType}/>
  }

  return (
    <div className="bg-blue-500 text-white min-h-screen flex flex-col">
      {/* About Modal component with its content */}
      <Modal isOpen={aboutModalOpen} handleClose={handleCloseAboutModal} title="About">
        {aboutModalContent}
      </Modal>

      {/* Get Started Modal component with its content */}
      <Modal isOpen={getStartedModalOpen} handleClose={handleCloseGetStartedModal}>
        {getStartedModalContent}
      </Modal>
      {/* Menu Bar */}
      <nav className="flex justify-between items-center p-4">
        <div className="flex items-center">
          <img src={logo} alt="Fluffball.app Logo" className="h-10 mr-2" />
          <span>fluffball</span>
        </div>
        <div className="flex gap-4">
          <button className={menuButtonClasses} onClick={handleOpenAboutModal}>About</button>
        </div>
      </nav>

      {/* Main Content */}
        <div>
          <TestimonialsSlider testimonials={testimonials} />
        </div>
      <main className="flex-grow flex flex-col md:flex-row sm:p-12">
        <div className="flex-1 flex flex-col pt-4 px-4">
          <h1 className="text-4xl sm:text-6xl mb-2 font-headline" style={{ lineHeight: '1.5' }}>Never saw your pet as a puppy or kitten?</h1>
          <p className="text-xl mb-2 py-2 sm:text-2xl sm:mb-4 sm:py-4">See how your dog or cat looked as a puppy or kitten!</p>
          
          <div className="flex justify-center items-center p-4">
            <button 
              onClick={handleOpenGetStartedModal} 
              className="text-lg md:text-xl px-6 py-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-gray-800 font-bold rounded-lg shadow-lg hover:from-yellow-500 hover:to-orange-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-50"
            >
              Get Started
            </button>
          </div>
        </div>
        <div className="flex-1 grid grid-cols-2 gap-4 px-4">
          {[...Array(4)].map((_, i) => (
            <img 
              key={i} 
              src={`./examples/petImage${i}.png`} // Assuming images are named image1.png, image2.png, etc.
              alt={`Content ${i}`} 
              className="w-full h-auto object-cover rounded-md"
            />
          ))}
        </div>
      </main>
      <div className='px-4 sm:px-16'>
        <InstagramWall posts={instagramPosts} linkToSeeMore={"https://www.instagram.com/stories/highlights/17918144279824183/?hl=en"}/>
      </div>
      {/* Testimonials Carousel */}

      {/* Footer */}
      <footer className="text-center p-4">
        <p className="text-xs px-4 py-2">
          Made with ❤️ in New York City
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;