import React, { useState, useEffect } from 'react';

const PetSelector = ({ onSubmit }) => {

  return (
    <div className="bg-white rounded-lg max-w-lg mx-auto p-6">
      <h3 className='text-2xl font-semibold text-gray-800 mb-2'>What kind of pet do you have? 🐾</h3>
      <div className="text-center">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 m-2 rounded transition-colors"
          onClick={() => onSubmit('cat')}
        >
          🐱 Cat
        </button>
        <button
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 m-2 rounded transition-colors"
          onClick={() => onSubmit('dog')}
        >
          🐶 Dog
        </button>
      </div>
      <p className="text-gray-600 mt-4 italic text-xs">
        Currently, we can't handle profiles for fish, hamsters, or reptiles. Sorry for the inconvenience!
      </p>
    </div>
  )
}

export default PetSelector;