import React, { useState } from 'react';
import { extractEmails, searchEmails, searchDocumentsByReviewer } from '../Firebase'; // Adjust the path as per your project structure
import SearchResult from './SearchResult'; // Import the new subcomponent

const EmailSearchComponent = () => {
  const [emails, setEmails] = useState(``);
  const [extractedEmails, setExtractedEmails] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenReviewed, setHasBeenReviewed] = useState(false);
  const [searchMode, setSearchMode] = useState('reviewer');

  const handleSearch = async () => {
    const extracted = extractEmails(emails.toLowerCase());
    setExtractedEmails(extracted);
    if (extracted.length === 0) {
      alert('No valid emails entered in search');
      return;
    }

    setIsLoading(true);
    try {
      const documents = await searchEmails(extracted, hasBeenReviewed);
      console.log(`found ${documents.length} documents`)
      setSearchResults(documents);
    } catch (error) {
      // Handle error
    }
    setIsLoading(false);
  };

  const handleChooseAssignment = async (a) => {
    setIsLoading(true);
    try {
      const documents = await searchDocumentsByReviewer(a, hasBeenReviewed);
      console.log(`found ${documents.length} documents`)
      setSearchResults(documents);
    } catch (error) {
      // Handle error
    }
    setIsLoading(false);
  };

  // ... existing state variables ...
  const [feedbackMessage, setFeedbackMessage] = useState('');

  // ... existing functions ...

  const handleStatusUpdate = (email, photoIndex, status, outputType) => {
    setFeedbackMessage(`Successfully marked image ${photoIndex + 1} for email ${email} as ${status} (${outputType})`);
    setTimeout(() => setFeedbackMessage(''), 2000);
  };

  const updateImageQualityInState = (docId, newImageQuality, outputType) => {
    setSearchResults(prevResults => 
      prevResults.map(doc => 
        doc.id === docId ? 
        (outputType == "special" ? { ...doc, special_image_quality: newImageQuality } : { ...doc, image_quality: newImageQuality } ) 
        : doc
      )
    );
  };

  return (
<div className="container mx-auto p-4">
  {/* Tab-like control for switching between search modes */}
  <div className="flex space-x-4 mb-4">
    <button
      className={`px-4 py-2 rounded-lg ${searchMode === 'email' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} focus:outline-none`}
      onClick={() => setSearchMode('email')}
    >
      Search by Email
    </button>
    <button
      className={`px-4 py-2 rounded-lg ${searchMode === 'reviewer' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} focus:outline-none`}
      onClick={() => setSearchMode('reviewer')}
    >
      Search by Reviewer
    </button>
  </div>

  {/* Conditional Rendering based on search mode */}
  {searchMode === 'email' ? (
    <>
      <textarea
        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={emails}
        onChange={(e) => setEmails(e.target.value)}
        placeholder="Paste emails here"
      />
      {extractedEmails.length > 0 && (
        <p>Parsed {extractedEmails.length} email(s): {extractedEmails.join(', ')}. Queries will be made in lower case</p>
      )}
      <button
        className={`mt-3 px-4 py-2 rounded-lg text-white font-semibold ${isLoading ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-600'} focus:outline-none`}
        onClick={handleSearch}
        disabled={isLoading}
      >
        {isLoading ? 'Searching...' : 'Search'}
      </button>
    </>
  ) : (
    <>
      {/* Reviewer Selection Buttons */}
      {[0, 1].map(reviewer => (
        <button
          key={reviewer}
          className={`mt-3 px-4 py-2 rounded-lg text-white font-semibold ${isLoading ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-600'} focus:outline-none mx-2`}
          onClick={() => handleChooseAssignment(reviewer)}
          disabled={isLoading}
        >
          {isLoading ? 'Searching...' : `Reviewer: ${reviewer}`}
        </button>
      ))}
    </>
  )}

  {/* Filter for Has Been Reviewed */}
  <button
    className={`mt-3 px-4 py-2 rounded-lg text-white font-semibold ${isLoading ? 'bg-gray-500' : 'bg-yellow-500 hover:bg-yellow-600'} focus:outline-none mx-2`}
    onClick={() => setHasBeenReviewed(!hasBeenReviewed)}
    disabled={isLoading}
  >
    {isLoading ? 'Searching...' : `Has been reviewed: ${hasBeenReviewed}`}
  </button>

  {/* Feedback Message */}
  {feedbackMessage && (
    <div className="fixed top-0 right-0 mb-3 ml-3 p-2 border border-green-300 bg-green-100 rounded-lg text-green-700 text-xs">
      {feedbackMessage}
    </div>
  )}

  {/* Search Results */}
  <div className="mt-3">
    {searchResults.length > 0 ? (
      searchResults.map((result, index) => (
        <SearchResult 
          key={index} 
          photoDoc={result} 
          onStatusUpdate={handleStatusUpdate} 
          updateImageQualityInState={updateImageQualityInState}
        />
      ))
    ) : (
      <p className="text-gray-700">No results found.</p>
    )}
  </div>
</div>


  );
};

export default EmailSearchComponent;
