import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loading-icons'
const PhotoUploader = ({ familyMembers, onUpload, onSubmit }) => {
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const [photosMap, setPhotosMap] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const minPhotos = 5; // Minimum number of photos required

  useEffect(() => {
    if (!photosMap[familyMembers[currentMemberIndex].name]) {
      setPhotosMap(prevMap => ({
        ...prevMap,
        [familyMembers[currentMemberIndex].name]: { files: [], urls: [] }
      }));
    }
  }, [currentMemberIndex, familyMembers, photosMap]);

  const handlePhotoChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newPhotoFiles = Array.from(event.target.files).slice(0, 10);
      const newPhotoURLs = newPhotoFiles.map(file => URL.createObjectURL(file));

      setPhotosMap(prevMap => ({
        ...prevMap,
        [familyMembers[currentMemberIndex].name]: {
          files: [...prevMap[familyMembers[currentMemberIndex].name].files, ...newPhotoFiles],
          urls: [...prevMap[familyMembers[currentMemberIndex].name].urls, ...newPhotoURLs]
        }
      }));
    }
  };

  const handleDeletePhoto = (photoIndex) => {
    setPhotosMap(prevMap => {
      const { files, urls } = prevMap[familyMembers[currentMemberIndex].name];
      return {
        ...prevMap,
        [familyMembers[currentMemberIndex].name]: {
          files: files.filter((_, index) => index !== photoIndex),
          urls: urls.filter((_, index) => index !== photoIndex)
        }
      };
    });
  };

  const handleNext = async () => {
    if (currentMemberIndex < familyMembers.length - 1) {
      setCurrentMemberIndex(currentMemberIndex + 1);
    } else {
      if (photosMap[familyMembers[currentMemberIndex].name]?.files.length >= minPhotos) {
        setIsUploading(true);
        const userId = await onUpload(familyMembers, photosMap);
        setIsUploading(false);
        onSubmit(userId);
      }
    }
  };

  const handlePrev = () => {
    if (currentMemberIndex > 0) {
      setCurrentMemberIndex(currentMemberIndex - 1);
    }
  };

  const photosNeeded = minPhotos - (photosMap[familyMembers[currentMemberIndex].name]?.files.length || 0);


  return (
    <div className="bg-white rounded-lg max-w-lg mx-auto">
      <h3 className='text-2xl font-semibold text-gray-800'>Upload photos of your pet</h3>
      <p className="text-gray-600 my-3 text-sm">Please upload 5 to 10 images of your pet.</p>
      <div className="text-gray-500 text-xs text-left bg-gray-100 m-2 rounded p-2">
        <p>💡 Tip: For best results, upload photos with</p>
        <p>- A simple background like a couch or grass</p>
        <p>-  No other people or animals in them</p>
        <p>- Clearly show your pet's face</p>
        <p>- Are not sideways (rotate them on your phone!)</p>
      </div>
      <input
        key={familyMembers[currentMemberIndex].name} // Reset input when current family member changes
        className='block w-full text-sm text-gray-600 file:border-0 file:rounded file:bg-blue-50 file:py-2 file:px-4 file:text-blue-700 file:cursor-pointer pt-2'
        type="file"
        multiple
        onChange={handlePhotoChange}
        disabled={isUploading}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-3">
        {photosMap[familyMembers[currentMemberIndex].name]?.urls.map((photo, index) => (
          <div key={index} className="relative">
            <img src={photo} alt="Uploaded" className="w-full h-auto rounded-lg shadow" />
            <button
              className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full opacity-100 transition-opacity"
              onClick={() => handleDeletePhoto(index)}
              disabled={isUploading}
            >
              X
            </button>
          </div>
        ))}
      </div>
      {photosNeeded > 0 && (
        <p className="text-red-600 text-xs mt-3">Add {photosNeeded} more photo{photosNeeded == 1 ? '' : 's'} to continue so we can make your card</p>
      )}
      {isUploading && 
        <div className="flex flex-col justify-center align-center mt-4">
          <div className="flex justify-center align-center mt-4"><TailSpin stroke="#5A5A5A" speed={.75} /></div>
          <p className="text-center text-blue-600 mt-3">Uploading images. Sit tight. This might take up to a minute...</p>
        </div>
      }
      <div className="flex justify-center align-center mt-4">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition-colors disabled:bg-blue-200"
          onClick={handleNext}
          disabled={isUploading || photosNeeded > 0}
        >
          {currentMemberIndex < familyMembers.length - 1 ? 'Next' : 'Upload and Continue'}
        </button>
      </div>
    </div>
  );
};

export default PhotoUploader;
