import React, { useState } from 'react';

const EmailModal = ({ userId, onSubmitEmail }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitEmail(userId, email);
  };

  return (
      <div className="bg-white p-4 rounded-lg">
        <h2 className="text-xl font-semibold text-black">Enter Your Email</h2>
        <p className="text-gray-600 mb-4">
          We'll send the photos to your email once they're ready!
        </p>
        <form onSubmit={handleSubmit} className="mt-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email"
            className="text-black border p-2 rounded w-full"
            required
          />
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded mt-3 w-full"
          >
            Submit
          </button>
        </form>
      </div>
  );
};

export default EmailModal;
