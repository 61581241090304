import React, { useState, useEffect } from 'react';
import { 
  updateChatImagesInFirestore,
  uploadImageToFirebase,
  getDocumentWithId,
  updateSharedStatus,
} from '../Firebase'; // Adjust the import path as needed
import { TailSpin } from 'react-loading-icons'


const CarouselImage = ({ url, isActive }) => (
  <div className={`w-full h-full ${isActive ? 'block' : 'hidden'}`}>
    <img src={url} className="w-full h-full object-cover blur-sm bg-black bg-opacity-50" alt="Holiday" />
  </div>
);


const ERROR_MESSAGE = `Our system doesn't think these images are sharing the correct link. Please update the indicated images and make sure you are sharing with different people. If you think this was an error, please try again`
const TIMEOUT_ERROR_MESSAGE = `We ran into a verification error. This sometimes happens. Try again`
const ShareAndVerifyImages = ({ output_images, image_quality, isDog, hasShared, docId }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(hasShared ? 4 : 1);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [messageCopied, setMessageCopied] = useState(false);
  const [imageValid, setImageValid] = useState([true, true, true, true, true]);
  const [verificationResponse, setVerificationResponse] = useState(null);
  const [imageCheckLoading, setImageCheckLoading] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === output_images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [output_images.length]);

  const petType = isDog ? 'dog' : 'cat';
  const petBaby = isDog ? 'puppy' : 'kitten';
  const predefinedMessage = `Hi, I used Fluffball to see what my ${petType} looked like as a ${petBaby} 🐶😺.\n\nThey're so cute 🥺! https://www.fluffball.app/result/${docId}.\n\n Try it out and send me the results! www.fluffball.app`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(predefinedMessage);
    setMessageCopied(true);
    setTimeout(() => setMessageCopied(false), 2000);
  };

  const handleImageUpload = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0])
      const filesArray = Array.from(event.target.files).slice(0,5);
      const newFiles = filesArray.map(file => ({
        url: URL.createObjectURL(file),
        file: file
      }));
      setUploadedImages([...uploadedImages, ...newFiles]);
    }
  };

  const removeImage = (imageIndex) => {
    setUploadedImages(uploadedImages.filter((_, index) => index !== imageIndex));
    setImageValid(imageValid.filter((_, index) => index !== imageIndex));
  };


  const handleRevealClick = async () => {
    setImageCheckLoading(true)
    console.log('uploadedImages')
    console.log(uploadedImages)
    console.log(uploadedImages[0])

    const uploadPromises = uploadedImages.map(item => uploadImageToFirebase(item));
    const imageUrls = await Promise.all(uploadPromises);
    console.log("getting image urls...")
    console.log("passing to chatgpt...")
    await updateChatImagesInFirestore(docId, imageUrls);


    let chatImageUrls = []
    // const verificationResponses = await Promise.all(imageUrls.map(url => isRealMessage(url)));
    await Promise.all(imageUrls.map(url => chatImageUrls.push(url)));
    await isRealMessage(chatImageUrls, docId)
    
    // setImageValid(messagesLegit)
    // let canProceed = true
    // messagesLegit.map(x => canProceed = canProceed && x)
    // // const allImagesValid = verificationResponses.every(response => response === 'OK');

    // if (canProceed) {
    //   setCurrentStep(4); // Proceed to reveal images
    //   setVerificationResponse(null)
    // } else{
    //   setVerificationResponse(ERROR_MESSAGE)
    // }
  };


 const renderGoodQualityImages = () => {
    return output_images
      .filter((_, index) => image_quality[index] === 'good')
      .map((url, index) => (
        <div key={index} className="w-full md:w-1/4 px-2 my-2">
          <img src={url} alt={`Quality Image ${index}`} className="w-full h- rounded-lg shadow-lg" />
        </div>
      ));
  };

  async function isRealMessage(imageUrls, docId) {
  
    // get the latest number of attempts

    const initialDoc = await getDocumentWithId(docId)
    let initialRevealAttempts = initialDoc['num_reveal_attempts']
    if (initialRevealAttempts === undefined)
        initialRevealAttempts = 0
    console.log(`setting initialRevealAttempts to ${initialRevealAttempts}`)
    
    console.log(`found doc. initialRevealAttempts: ${initialRevealAttempts}...`)

    // tell backend to verify
    const endpoint = "https://fluffball-backend-92242421f200.herokuapp.com/verify_chat_images";
    console.log(`calling /verify_chat_images...`)
    // const endpoint = "http://127.0.0.1:5000/verify_chat_images";
    const data = {
        "image_urls": imageUrls,
        "doc_id": docId,
    };

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    // Set the interval to call function f every 5 seconds


    const imageVerificationIsComplete = async () => {
      console.log(`checking if verification is complete...`)
      const updatedDoc = await getDocumentWithId(docId)
      let updatedRevealAttempts = updatedDoc['num_reveal_attempts']
      if (updatedRevealAttempts === undefined)
        updatedRevealAttempts = 0

      console.log(`updated_attempts: ${updatedRevealAttempts}`)

      if (updatedRevealAttempts > initialRevealAttempts){
        console.log(`verification complete ${updatedRevealAttempts} > ${initialRevealAttempts}`)
        const updatedImageMetrics = updatedDoc['chat_image_validity']
        console.log(`metrics: ${updatedImageMetrics}`)
        // conditions for reveal: 4/5 photos are legit; no repeated names
        // flag bad images if > 1 is bad and repeated names
        let imageQuality = []
        let sharedWithCount = new Map()
        let numImagesBad = 0

        let countValid = 0;

        for (let i = 0; i < updatedImageMetrics.length; i++) {
          const metrics = updatedImageMetrics[i]
          try {

            const includesFluffball = metrics['includes_fluffball']
            const isChatScreenshot = metrics['is_chat_screenshot']
            const sharedWith = metrics['shared_with']
            sharedWithCount[sharedWith] = sharedWithCount[sharedWith] === undefined ? 1 : sharedWithCount[sharedWith]+1
            const sharedWithDuplicate = sharedWithCount[sharedWith] > 1
        
            imageQuality.push((includesFluffball && !sharedWithDuplicate))
          } catch (error) {
            imageQuality.push(false)
          }
          countValid += imageQuality[i] ? 1 : 0


        }
        console.log(`imageQuality: ${imageQuality}`)
        setImageValid(imageQuality)
        if (countValid >= 4){
          console.log(`revealing images...`)
          await updateSharedStatus(docId)
          setCurrentStep(4)

        } else {
          setVerificationResponse(ERROR_MESSAGE)
        }
        setImageCheckLoading(false)
        return true
      } else {
        console.log(`latest attempt has not completed...`)
        return false
      }
    }
    // Set the interval to call function f every 5 seconds
    const intervalId = setInterval( async() => {
        const shouldStop = await imageVerificationIsComplete();
        console.log(`should stop: ${shouldStop}`)
        if (shouldStop) {
            clearInterval(intervalId);
            console.log('Function returned true, stopped calling.');
        }
    }, 3000);

    // Stop calling f after 60 seconds
    setTimeout(() => {
        setVerificationResponse(TIMEOUT_ERROR_MESSAGE)
        setImageCheckLoading(false)
        clearInterval(intervalId);
        console.log('Stopped calling function');
    }, 120000);



}




  return (
    <div className="flex rounded-lg p-6 w-full bg-green-900 text-white">
    {currentStep <= 3 && 
        <div className='flex-col md:flex-row flex w-full justify-center align-center'>
          <div className='w-full md:w-1/3 justify-center'>
            <div className='h-96'>
            {output_images.map((url, index) => (
              <CarouselImage key={index} url={url} isActive={currentImageIndex === index} />
            ))}
            </div>
          </div>
          <div className='flex flex-col w-full m-2 md:w-2/3'>

          <div className='flex flex-col'>
            <p className="text-2xl font-bold">🎄 Holiday photos</p>
            <p className="text-xs">As a thank you for being an early user, we made holiday photos of your pet!</p>
          </div>
          <div className='flex h-full justify-center items-center'>
            {currentStep === 1 && (
              <div>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors"
                  onClick={() => setCurrentStep(2)}
                >
                  Reveal
                </button>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <p className="text-center mb-4">Share your fluffball photos with five friends.</p>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors block mx-auto"
                  onClick={() => setCurrentStep(3)}
                >
                  Next
                </button>
              </div>
            )}

            {currentStep === 3 && (
              <div className='flex flex-col p-2'>
                <div className='flex pt-2'>
                  <p className='font-bold '>Step 1: Share this message with 5 friends with pets!</p>
                </div>
                <div className="bg-green-700 p-4 rounded my-2">
                  <p className="m-2 text-xs">{predefinedMessage}</p>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors block mx-auto mt-2"
                    onClick={copyToClipboard}
                  >
                    {messageCopied ? "Copied!" : "Copy"}
                  </button>
                </div>
                <div className='py-2'>
                  <p className='font-bold '>Step 2: Upload 5 screenshots of your chats to show us you shared your photos 😃</p>
                </div>
                <div className='flex w-full align-center items-center justify-center'>

                <input
                  className="w-full file:border-0 file:rounded file:bg-blue-500 file:text-white file:py-2 file:px-4 file:cursor-pointer mb-4 text-sm"
                  type="file"
                  multiple
                  onChange={handleImageUpload}
                />
                </div>

                <div className="grid grid-cols-6 gap-2">
                  {uploadedImages.map((image, index) => (
                    <div key={index} className={`relative border-4 ${imageValid[index] == true ? 'border-green-500' : 'border-red-500'}`}>
                      <img src={image.url} alt={`Uploaded ${index}`} className="w-full h-auto rounded-lg shadow" />
                      <button
                        className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                        onClick={() => removeImage(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>

                {/* Display error message if verification response is "incorrect" */}
                {verificationResponse !== null && (
                  <p className="bg-red-200 rounded m-2 p-2 text-red-600 text-center text-sm">{verificationResponse}</p>
                )}
                {imageCheckLoading && 
                  <div className="flex flex-col justify-center align-center mt-4">
                    <div className="flex justify-center align-center mt-4"><TailSpin stroke="#FFFFFF" speed={.75} /></div>
                  </div>
                }
                <button
                  className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors block mx-auto mt-4 disabled:bg-red-400"
                  onClick={handleRevealClick}
                  disabled={uploadedImages.length < 5 || imageCheckLoading}
                >
                  {imageCheckLoading ? 'Checking your screenshots... This might take up to two minutes' : uploadedImages.length < 5 ? `Share with ${5-uploadedImages.length} more friend${5-uploadedImages.length > 1 ? 's' : ''} to reveal your photos!` : 'Reveal'}
                </button>

              </div>
            )}
            </div>
          </div>
        </div>
      }
      {/* Display good quality images if all steps are complete */}
      {currentStep > 3 && (
        <div className="flex flex-wrap justify-center">
          {renderGoodQualityImages()}
        </div>
      )}
    </div>
  );
};

export default ShareAndVerifyImages;
