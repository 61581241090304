import React from 'react';

const InstagramWall = ({ posts }) => {
  const getRelativeTime = (timestamp) => {
    const postDate = new Date(timestamp);
    const currentDate = new Date();
    const differenceInTime = currentDate - postDate;
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays === 0) {
      return 'Today';
    } else {
      return `${differenceInDays}d ago`;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl md:text-2xl font-semibold text-center mb-6 md:mb-8">From our community</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
        {posts.map((post, index) => (
          <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col">
            <a href={post.link} className="flex-grow">
              <img src={post.imageUrl} alt={post.caption} className="w-full" />
              <div className="p-3 md:p-4">
                <p className="text-gray-800 text-sm font-semibold">{post.username}</p>
                <p className="text-gray-600 text-xs">{getRelativeTime(post.timestamp)}</p>
                <p className="text-gray-700 text-xs mt-1 md:mt-2">{post.caption}</p>
              </div>
            </a>
            <a href={post.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 text-xs p-3 md:p-4 mt-auto inline-block cursor-pointer">See on Instagram</a>
          </div>
        ))}
        <a href="https://www.instagram.com/stories/highlights/17918144279824183/?hl=en" target="_blank" rel="noopener noreferrer" className="bg-blue-400 rounded-lg flex items-center justify-center text-white text-sm font-semibold p-3 md:p-4 cursor-pointer">
          See more on our page →
        </a>
      </div>
    </div>
  );
};

export default InstagramWall;
