import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../Firebase';
import logo from '../images/mascot.png';
import Modal from '../About.jsx';
import HolidayCardReveal from './HolidayCardReveal.jsx';
import ShareAndVerifyImages from './ShareAndVerifyImages.jsx';
import ViewAndDownloadVideo from './ViewAndDownloadVideo.jsx';

const menuButtonClasses = "px-4 py-2 hover:bg-white hover:text-blue-500 transition-colors duration-300 rounded-md";
const aboutModalContent = (
  <div className="">
    <h2 className="text-lg font-semibold text-blue-800 mb-2">About</h2>
    <p className="text-sm text-gray-700">
      We built this app to help you bond with your special friend and see them at their cutest. Enjoy!
    </p>
  </div>
);

const ResultsPage = () => {
  const { docId } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [yourVideo, setYourVideo] = useState(false);
  const [sampleVideo, setSampleVideo] = useState(false);


  const handleOpenAboutModal = () => {
    setAboutModalOpen(true);
  };

  const handleCloseAboutModal = () => {
    setAboutModalOpen(false);
  };

  useEffect(() => {
    const fetchDocument = async () => {
      const db = getFirestore(app);
      const docRef = doc(db, "petPhotos", docId);

      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDocumentData(docSnap.data());
        } else {
          console.log("No such document!");
          setError("Document not found");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
        setError("Error fetching document");
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [docId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Function to render good quality images
  const renderGoodQualityImages = () => {
    if (!documentData || !documentData.output_images || !documentData.image_quality) {
      return <div>No images available</div>;
    }

    return documentData.output_images
      .map((url, index) => ({
        url,
        quality: documentData.image_quality[index]
      }))
      .filter(item => item.quality === 'good')
      .map((item, index) => (
        <div key={index} className="w-1/2 md:w-1/4 px-2 mb-4">
          <img src={item.url} alt={`Quality Image ${index}`} className="w-full h-auto rounded-lg shadow-lg" />
        </div>
      ));
  };

  const renderDiscordInvite = () => {
    return (
      <div>
        <h2 className="text-3xl text-gray-700 font-semibold mb-3 mt-20">Want to create more photos of your pet?</h2>
        <p className="text-gray-600 mb-4">
          Head to our
          {' '}
          <a href="https://discord.gg/GJ8WmUkNXv" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            Discord
          </a>
          {' '}
          and follow the instructions in the <code className="bg-gray-100 text-gray-800 font-mono p-1 rounded">#welcome</code> message. Your <code className="bg-gray-100 text-gray-800 font-mono p-1 rounded">pet_code</code> is <code className="bg-gray-100 text-gray-800 font-mono p-1 rounded">{docId}</code>.
        </p>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <Modal isOpen={aboutModalOpen} handleClose={handleCloseAboutModal} title="About">
        {aboutModalContent}
      </Modal>
      <nav className="flex justify-between items-center p-4">
        <div className="flex items-center">
          <img src={logo} alt="Fluffball.app Logo" className="h-10 mr-2" />
          <span>fluffball</span>
        </div>
        <div className="flex gap-4">
          <button className={menuButtonClasses} onClick={handleOpenAboutModal}>About</button>
        </div>
      </nav>
      <div>
        {
          (documentData.produced_video_url != null) ?
          (
            
            <div>
              <h2 className="text-3xl text-gray-700 font-semibold mb-3 mt-20">We made you a video!</h2>
              <div className="flex flex-wrap justify-center -mx-2">
                <ViewAndDownloadVideo yourVideo={documentData.video} sampleVideo={documentData.produced_video_url}/>
              </div>
            </div>
          ) : null
        }
        <h2 className="text-3xl text-gray-700 font-semibold mb-3 mt-20">Your {documentData.petType == 'dog' ? 'puppy' : 'kitten'} photos have arrived!</h2>
        <p className="text-gray-600 mt-2 mb-6">
          ⭐ Tag us when you share these photos on social media for a free picture set promo code!
        </p>
        <div className="flex flex-wrap justify-center -mx-2">
          {renderGoodQualityImages()}
        </div>
        {renderDiscordInvite()}
        {
          documentData.output_special_images && documentData.output_special_images.length > 0 && (
            <div>
              <h2 className="text-3xl text-gray-700 font-semibold mb-3 mt-20">Special photos</h2>
              <div className="flex flex-wrap justify-center px-2 -mx-2 my-2">
                
                <ShareAndVerifyImages
                  output_images={documentData.output_special_images}
                  image_quality={documentData.special_image_quality}
                  isDog={documentData.petType == 'dog' ? true : false}
                  hasShared={documentData.shared_screenshots_verified != null}
                  docId={docId}
                />
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ResultsPage;
